<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="$primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{Title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items></v-toolbar-items>
      </v-toolbar>
      <v-data-table
        item-key="strlinid"
        class="elevation-0 ma-12 pa-12"
        v-model="selected"
        :headers="headers"
        :items="Items"
        show-select
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:top>
          <div class="d-flex align-center">
            <v-layout wrap>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  class="pr-5"
                  :color="$primary"
                  :disabled="selected.length==0"
                  v-model="assunto"
                  label="Título do orçamento"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  class="pr-5"
                  :color="$primary"
                  :disabled="selected.length==0"
                  v-model="totalsem"
                  label="Total s/ IVA"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  class="pr-5"
                  :color="$primary"
                  :disabled="selected.length==0"
                  v-model="totalcom"
                  label="Total c/ IVA"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  class="pr-5"
                  :color="$primary"
                  :disabled="selected.length==0"
                  v-model="pagamento"
                  label="Condição pagamento"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  class="pr-5"
                  :color="$primary"
                  :disabled="selected.length==0"
                  v-model="obs"
                  label="Observações"
                  rows="1"
                  :auto-grow="true"
                ></v-textarea>
              </v-col>
            </v-layout>
            <v-btn
              class="white--text ml-5"
              :disabled="selected.length==0"
              :color="$primary"
              @click="exportar()"
            >Exportar</v-btn>
          </div>
        </template>
        <template v-slot:item.decpricesellun="{ item }">{{ item.decpricesellun.toFixed(2) }} €</template>
        <template v-slot:item.dectotalliq="{ item }">{{ item.dectotalliq.toFixed(2) }} €</template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    MyObject: Object,
    Title: String,
    Items: {
      type: Array,
      default: []
    }
  },
  computed: {
    getSession() {
      return this.$store.getters["session/getState"]
    }
  },
  data: () => ({
    dialog: false,
    assunto: "",
    obs: "",
    pagamento: "",
    totalcom: "",
    totalsem: "",
    selected: [],
    OBJ: {},
    headers: [
      { text: "ITEM", value: "stritem" },
      { text: "DESCRIÇÂO", value: "stritemname" },
      { text: "UNIDADE", value: "strunity" },
      { text: "QUANTIDADE", value: "decqtt" },
      { text: "PREÇO UNITÁRIO", value: "decpricesellun" },
      { text: "TOTAL", value: "dectotalliq" }
    ]
  }),
  watch: {},
  mounted() {},
  created() {
    this.$eventBus.$on("LinhaDialog", state => {
      this.dialog = state

      if (this.MyObject.hasOwnProperty("clientecodigo") && state) {
        let obj = {
          nome: this.MyObject.clientenome,
          telemovel: this.MyObject.clientetelemovel,
          telefone: this.MyObject.clientetelefone,
          morada: this.MyObject.clientemorada,
          localidade: this.MyObject.clientelocalidade,
          orcamento: this.Title.split("/")[1].trim()
        }

        this.OBJ = { ...obj }
      } else if (state) this.OBJ = { ...this.MyObject }
    })
  },
  methods: {
    exportar() {
      if (
        this.selected.length == 0 ||
        !this.assunto ||
        !this.totalsem ||
        !this.totalcom
      )
        return

      let nome = this.$moment().unix()

      this.$eventBus.$emit("loader", true)

      this.$store
        .dispatch("at/print", {
          nome: nome,
          assunto: this.assunto,
          obs: this.obs,
          pagamento: this.pagamento,
          cliente: this.OBJ,
          linhas: this.selected,
          totalsem: this.totalsem,
          totalcom: this.totalcom
        })
        .then(res => {
          this.$eventBus.$emit("loader", false)

          if (res) window.open(this.$files + nome + ".pdf", "_blank")
          else this.$toast.error(this.$error)
        })
    }
  }
}
</script>
<style scoped>
</style>
